import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {useAuth0} from "@auth0/auth0-react";
import Sidebar from '../components/Sidebar/Sidebar';
import MainContent from '../components/MainContent/MainContent';
import Footer from '../components/Footer/Footer';
import GridContainer from '../components/GridContainer/GridContainer';
import TradeAnalytics from '../components/TradeAnalytics/TradeAnalytics';
import TradeHistory from "../components/TradeHistory/TradeHistory";
import {
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {getApiUrl} from '../config';

function TradingPage() {
    const { getAccessTokenSilently, user } = useAuth0();
    const [buyOrders, setBuyOrders] = useState([]);
    const [sellOrders, setSellOrders] = useState([]);
    const [trades, setTrades] = useState([]);
    const [lastOrderDetails, setLastOrderDetails] = useState(null);
    const [order, setOrder] = useState({
        symbol: "BTC",
        side: "1",
        price: "",
        quantity: "",
        timestamp: null,
        orderType: "MARKET",
        status: "NEW",
        expiryTimestamp: dayjs().add(1, 'day').unix(),
    });
    const [setErrors] = useState({});
    const [feedback, setFeedback] = useState('');
    const [setOrderResponse] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const placeOrdersUrl = `${getApiUrl('javaBackend')}/trade`;
    const [activeTab, setActiveTab] = useState(0);


    const connectWebSocket = useCallback(async () => {
        const token = await getAccessTokenSilently();
        const socket = new WebSocket(`ws://localhost:8080/trade-updates?token=${token}`);

        socket.onopen = () => {
            console.log('WebSocket connection established');
        };

        socket.onmessage = (event) => {
            const tradeUpdate = JSON.parse(event.data);
            console.log('Received trade update:', tradeUpdate);
            handleOrderUpdate(tradeUpdate);
        };

        socket.onclose = () => {
            console.log('WebSocket connection closed');
            // Attempt to reconnect after a delay
            setTimeout(connectWebSocket, 5000);
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        return socket;
    }, [getAccessTokenSilently]);

    useEffect(() => {
        let socket;
        const setupSocket = async () => {
            socket = await connectWebSocket();
        };
        setupSocket();
        return () => {
            if (socket) socket.close();
        };
    }, [connectWebSocket]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleCancelAllOrders = () => {
        console.log('Cancel all orders');
        handleMenuClose();
        // Implement the logic to cancel all orders
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrder(prevOrder => ({
            ...prevOrder,
            [name]: value
        }));
    };

    const handleExpiryTimestampChange = (newValue) => {
        if (newValue.isAfter(dayjs())) {
            setOrder(prevOrder => ({
                ...prevOrder,
                expiryTimestamp: newValue.unix(),
            }));
        }
    };

    const validateOrder = (order) => {
        let errors = {};
        if (!order.quantity || order.quantity <= 0) {
            errors.quantity = 'Quantity must be greater than zero.';
        }
        if (!order.price || order.price <= 0) {
            errors.price = 'Price must be greater than zero.';
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateOrder(order);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setErrors({});
        setFeedback('Submitting order...');

        try {
            const token = await getAccessTokenSilently();
            const response = await axios.post(placeOrdersUrl, order, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.error) {
                setFeedback(response.data.error);
                return;
            }

            const newOrder = response.data.order;
            setOrderResponse(newOrder);
            setLastOrderDetails({ order: newOrder });

            setOrder({
                symbol: "BTC",
                side: "1",
                price: "",
                quantity: "",
                timestamp: null,
                orderType: "MARKET",
                status: "NEW",
                expiryTimestamp: dayjs().add(1, 'day').unix(),
            });

            setFeedback('Order submitted successfully!');

            if (newOrder.side === 1) {
                setBuyOrders(prevOrders => [...prevOrders, newOrder]);
            } else if (newOrder.side === 2) {
                setSellOrders(prevOrders => [...prevOrders, newOrder]);
            }

            console.log('Order submitted:', response.data);
        } catch (error) {
            console.error('Error submitting order:', error);
            setFeedback('Error submitting order. Please try again.');
        }
    };

    const handleOrderUpdate = (tradeUpdate) => {
        console.log('Handling order update:', tradeUpdate);

        setBuyOrders(prevOrders => {
            return prevOrders.map(order => {
                if (order.clientOrderId === tradeUpdate.buyClientOrderId) {
                    return {
                        ...order,
                        status: tradeUpdate.tradedQuantity < order.quantity ? 'PARTIALLY_FILLED' : 'FILLED',
                        fillPrice: tradeUpdate.fillPrice,
                        fillTime: tradeUpdate.fillTime,
                        fillQty: tradeUpdate.tradedQuantity,
                    };
                }
                return order;
            });
        });

        setSellOrders(prevOrders => {
            return prevOrders.map(order => {
                if (order.clientOrderId === tradeUpdate.sellClientOrderId) {
                    return {
                        ...order,
                        status: tradeUpdate.tradedQuantity < order.quantity ? 'PARTIALLY_FILLED' : 'FILLED',
                        fillPrice: tradeUpdate.fillPrice,
                        fillTime: tradeUpdate.fillTime,
                        fillQty: tradeUpdate.tradedQuantity,
                    };
                }
                return order;
            });
        });

        setTrades(prevTrades => {
            console.log('Adding trade to trades:', tradeUpdate);
            return [...prevTrades, tradeUpdate];
        });
    };

    const handleCancelOrder = async (orderId) => {
        try {
            const token = await getAccessTokenSilently();
            await axios.post(`${getApiUrl('javaBackend')}/cancel-order`, { orderId }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log(`Order with ID ${orderId} canceled`);
            // Update the order lists to reflect the cancellation
            setBuyOrders(prevOrders => prevOrders.filter(order => order.clientOrderId !== orderId));
            setSellOrders(prevOrders => prevOrders.filter(order => order.clientOrderId !== orderId));
        } catch (error) {
            console.error('Error canceling order:', error);
            setFeedback('Error canceling order. Please try again.');
        }
    };

    const renderLastOrderDetailsComponent = () => {
        if (!lastOrderDetails || !lastOrderDetails.order) return null;
        const { clientOrderId, symbol, price, quantity, status, expiryTimestamp } = lastOrderDetails.order;
        return (
            <Paper style={{ padding: '10px', marginTop: '10px', backgroundColor: '#e8f4fc' }}>
                <Typography variant="h6">Last Order Details</Typography>
                <Typography><strong>Order ID:</strong> {clientOrderId}</Typography>
                <Typography><strong>Symbol:</strong> {symbol}</Typography>
                <Typography><strong>Price:</strong> {price}</Typography>
                <Typography><strong>Quantity:</strong> {quantity}</Typography>
                <Typography><strong>Status:</strong> {status}</Typography>
                <Typography><strong>Expiry Timestamp:</strong> {dayjs.unix(expiryTimestamp).format('YYYY-MM-DD HH:mm:ss')}</Typography>
            </Paper>
        );
    };

    const renderOrdersTable = (orders, title) => (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Typography variant="h5" align="center" sx={{ marginY: 1, backgroundColor: '#f3f3f3', padding: '10px' }}>
                {title}
            </Typography>
            <Table sx={{ minWidth: 650, '& .MuiTableCell-root': { padding: '8px' } }} aria-label={`${title.toLowerCase()} order book table`}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            Cancel
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleMenuClick}
                                size="small"
                                sx={{ ml: 1 }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleCancelAllOrders}>
                                    Cancel All Orders
                                </MenuItem>
                            </Menu>
                        </TableCell>
                        <TableCell align="center">{title} Order ID</TableCell>
                        <TableCell align="center">Price(USD)</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Fill Price</TableCell>
                        <TableCell align="center">Fill Time</TableCell>
                        <TableCell align="center">Fill Qty</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map((order) => (
                        <TableRow key={order.clientOrderId} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: '#f0f0f0' } }}>
                            <TableCell align="center">
                                <Button variant="contained" color="secondary" onClick={() => handleCancelOrder(order.clientOrderId)}>
                                    Cancel
                                </Button>
                            </TableCell>
                            <TableCell align="center">{order.clientOrderId}</TableCell>
                            <TableCell align="center">{order.price}</TableCell>
                            <TableCell align="center">{order.quantity}</TableCell>
                            <TableCell align="center">{order.status}</TableCell>
                            <TableCell align="center">{order.fillPrice || 'N/A'}</TableCell>
                            <TableCell align="center">{order.fillTime ? dayjs.unix(order.fillTime).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}</TableCell>
                            <TableCell align="center">{order.fillQty || 'N/A'}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <GridContainer>
                <Sidebar setActiveTab={setActiveTab} />
                <MainContent trades={trades}>
                    <Tabs value={activeTab} onChange={handleTabChange} aria-label="trading tabs">
                        <Tab label="Make a Trade" />
                        <Tab label="Trade Analytics" />
                        <Tab label="Trade History" />
                    </Tabs>

                    {activeTab === 0 && (
                        <Box sx={{ mt: 2 }}>
                            <form onSubmit={handleSubmit}>
                                <h2>Make a Trade</h2>

                                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                                    <TextField
                                        select
                                        label="Symbol"
                                        name="symbol"
                                        value={order.symbol}
                                        onChange={handleChange}
                                        fullWidth
                                    >
                                        <MenuItem value="BTC">BTC</MenuItem>
                                        <MenuItem value="ETH">ETH</MenuItem>
                                    </TextField>

                                    <TextField
                                        select
                                        label="Side"
                                        name="side"
                                        value={order.side}
                                        onChange={handleChange}
                                        fullWidth
                                    >
                                        <MenuItem value="1">Buy</MenuItem>
                                        <MenuItem value="2">Sell</MenuItem>
                                    </TextField>
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: 2 }}>
                                    <TextField
                                        label="Price"
                                        name="price"
                                        type="number"
                                        value={order.price}
                                        onChange={handleChange}
                                        required
                                        sx={{ flexBasis: '30%' }}
                                    />

                                    <TextField
                                        label="Quantity"
                                        name="quantity"
                                        type="number"
                                        value={order.quantity}
                                        onChange={handleChange}
                                        required
                                        sx={{ flexBasis: '30%' }}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: 2 }}>
                                    <TextField
                                        select
                                        label="Order Type"
                                        name="orderType"
                                        value={order.orderType}
                                        onChange={handleChange}
                                        fullWidth
                                    >
                                        <MenuItem value="MARKET">Market</MenuItem>
                                        <MenuItem value="LIMIT">Limit</MenuItem>
                                    </TextField>
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: 2 }}>
                                    <DateTimePicker
                                        label="Expiry Timestamp"
                                        value={dayjs.unix(order.expiryTimestamp)}
                                        onChange={handleExpiryTimestampChange}
                                        minDateTime={dayjs()}  // Disable past dates/times
                                        renderInput={(params) => (
                                            <TextField {...params} required sx={{ flexBasis: '30%' }} />
                                        )}
                                    />
                                </Box>

                                <Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
                                    Submit Order
                                </Button>
                                {feedback && <Typography color="error" sx={{ my: 2 }}>{feedback}</Typography>}
                                {renderLastOrderDetailsComponent()}
                            </form>

                            {/* Place Buy and Sell tables side by side */}
                            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                                <Box sx={{ flex: 1 }}>
                                    {renderOrdersTable(buyOrders, 'Buy')}
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    {renderOrdersTable(sellOrders, 'Sell')}
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {activeTab === 1 && (
                        <TradeAnalytics userId={user.sub} />
                    )}

                    {activeTab === 1 && (
                        <TradeHistory />
                    )}
                </MainContent>
                <Footer />
            </GridContainer>
        </LocalizationProvider>
    );
}

export default TradingPage;
