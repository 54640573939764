import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Auth0Provider } from '@auth0/auth0-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomePage from './components/HomePage/HomePage';
import TradingPage from './pages/TradingPage';
import DashboardPage from './pages/DashboardPage';
import NavBar from './components/NavBar/NavBar';
import CryptoTicker from './components/CryptoTicker/CryptoTicker';
import SettingsPage from './pages/SettingsPage';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import SessionWarning from './components/Auth/SessionWarning';
import { ROLES } from './utils/auth';
import './App.css';

const theme = createTheme({
    palette: {
        primary: {
            main: '#556cd6',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: '#ff0000',
        },
        background: {
            default: '#fff',
        },
    },
});

const App = () => {
    const onRedirectCallback = (appState) => {
        console.log('Redirect callback state:', appState);
        window.history.replaceState(
            {},
            document.title,
            appState?.returnTo || window.location.pathname
        );
    };

    const getCallbackUrl = () => {
        const hostname = window.location.hostname;
        console.log('Current hostname:', hostname);

        let callbackUrl;
        if (hostname === 'localhost') {
            callbackUrl = 'http://localhost:3000/auth/callback';
        } else if (hostname.includes('cloudfront.net')) {
            callbackUrl = 'https://d3ly3jd5m1qvn9.cloudfront.net/auth/callback';
        } else {
            callbackUrl = 'https://radix.trading/auth/callback';
        }

        console.log('Using callback URL:', callbackUrl);
        console.log('Environment variables:', {
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            callbackPath: process.env.REACT_APP_AUTH0_CALLBACK_PATH
        });

        return callbackUrl;
    };

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: getCallbackUrl(),
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            }}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            cacheLocation="localstorage"
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <NavBar />
                    <CryptoTicker />
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/auth/callback" element={<HomePage />} />
                        <Route
                            path="/trade"
                            element={
                                <ProtectedRoute requiredRoles={[ROLES.TRADER, ROLES.ADMIN]}>
                                    <TradingPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/dashboard"
                            element={
                                <ProtectedRoute requiredRoles={[ROLES.VIEWER, ROLES.TRADER, ROLES.ADMIN]}>
                                    <DashboardPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/settings/*"
                            element={
                                <ProtectedRoute requiredRoles={[ROLES.ADMIN]}>
                                    <SettingsPage />
                                </ProtectedRoute>
                            }
                        />
                    </Routes>
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                        style={{
                            zIndex: 9999,
                        }}
                        toastStyle={{
                            borderRadius: '4px',
                            fontSize: '14px',
                        }}
                    />
                    <SessionWarning />
                </Router>
            </ThemeProvider>
        </Auth0Provider>
    );
};

export default App;