import React, { useState, useEffect } from 'react';
import { Typography, Grid, Paper, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';

const DashboardContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 100px)',
    backgroundColor: '#f5f5f5',
    paddingTop: '100px',
});

const Title = styled(Typography)({
    fontSize: '2.5rem',
    textAlign: 'center',
    color: '#333',
    marginBottom: '1rem',
});

const StyledPaper = styled(Paper)({
    margin: '20px',
    padding: '20px',
    flexGrow: 1,
    textAlign: 'center',
});

const DashboardPage = () => {
    // Example data for portfolio and watchlist
    const portfolio = [
        { name: 'Bitcoin', holdings: '1.5 BTC', value: '$65,570.88' },
        { name: 'Ethereum', holdings: '10 ETH', value: '$24,431.10' },
    ];

    const watchlist = [
        { name: 'Solana', price: '$150.30', change: '5.67%' },
        { name: 'ChainLink', price: '$17.30', change: '20.10%' },
    ];

    const [topMovers, setTopMovers] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTopMovers = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=volume_desc&per_page=10&page=1&sparkline=false');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setTopMovers(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTopMovers();
    }, []);

    return (
        <DashboardContainer>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Title>Dashboard</Title>
                </Grid>
                <Grid item xs={12} md={8}>
                    <StyledPaper>
                        <Typography variant="h6">My Balance</Typography>
                        <Typography variant="h4">$813,420.69</Typography>
                    </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <StyledPaper>
                        <Typography variant="h6">Top Movers</Typography>
                        {isLoading ? (
                            <Typography>Loading...</Typography>
                        ) : error ? (
                            <Typography>Error: {error}</Typography>
                        ) : (
                            <List>
                                {topMovers.map((mover, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={mover.name}
                                            secondary={`Price: $${mover.current_price.toFixed(2)} — 24h Change: ${mover.price_change_percentage_24h.toFixed(2)}%`}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </StyledPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <StyledPaper>
                        <Typography variant="h6">Portfolio</Typography>
                        <List>
                            {portfolio.map((item, index) => (
                                <ListItem key={index}>
                                    <ListItemText
                                        primary={item.name}
                                        secondary={`Holdings: ${item.holdings} — Value: ${item.value}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </StyledPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <StyledPaper>
                        <Typography variant="h6">Watchlist</Typography>
                        <List>
                            {watchlist.map((item, index) => (
                                <ListItem key={index}>
                                    <ListItemText
                                        primary={item.name}
                                        secondary={`Price: ${item.price} — Change: ${item.change}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </StyledPaper>
                </Grid>
            </Grid>
        </DashboardContainer>
    );
};

export default DashboardPage;