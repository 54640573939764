// components/MainContent.js
import React from 'react';
import './MainContent.css';
import TradeChart from '../TradeChart/TradeChart';

const MainContent = ({ children, trades = [] }) => {
    return (
        <div className="main-content">
            <div className={"main-content-inner"}>
                {children}  {/* Render children components here */}
                <TradeChart trades={trades} />
            </div>
        </div>
    );
};

export default MainContent;
