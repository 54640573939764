// src/components/NavBar/NavBar.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import { useAuth0 } from "@auth0/auth0-react";
import { notify } from '../../utils/notifications';

const StyledButton = styled(Button)({
    color: '#fff',
    marginRight: '10px',
});

const StyledToolbar = styled(Toolbar)({
    justifyContent: 'space-between',
    width: 'calc(100% - 200px)',
});

const NavBarTitle = styled(Typography)({
    flexGrow: 0,
    marginRight: 'auto',
});

const NavBar = () => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    const handleLogin = () => {
        notify.info('Redirecting to login...');
        loginWithRedirect();
    };

    const handleLogout = () => {
        logout({ returnTo: window.location.origin });
        notify.success('Logged out successfully');
    };

    return (
        <AppBar position="static">
            <StyledToolbar>
                <NavBarTitle variant="h6">
                    Radix
                </NavBarTitle>
                {isAuthenticated ? (
                    <>
                        <StyledButton component={RouterLink} to="/dashboard">Dashboard</StyledButton>
                        <StyledButton component={RouterLink} to="/trade">Trade</StyledButton>
                        <StyledButton component={RouterLink} to="/settings">Settings</StyledButton>
                        <StyledButton onClick={handleLogout}>Log Out</StyledButton>
                    </>
                ) : (
                    <>
                        <StyledButton onClick={handleLogin}>Log In / Sign Up</StyledButton>
                    </>
                )}
            </StyledToolbar>
        </AppBar>
    );
};

export default NavBar;