// src/components/Auth/SessionWarning.js
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    LinearProgress
} from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";

const SessionWarning = () => {
    const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [open, setOpen] = useState(false);
    const [timeLeft, setTimeLeft] = useState(300); // 5 minutes in seconds

    useEffect(() => {
        // Check token every minute
        const checkTokenInterval = setInterval(async () => {
            try {
                await getAccessTokenSilently({ ignoreCache: true });
            } catch (error) {
                if (error.message?.includes('expired')) {
                    setOpen(true);
                }
            }
        }, 60000);

        return () => clearInterval(checkTokenInterval);
    }, [getAccessTokenSilently]);

    useEffect(() => {
        let timer;
        if (open && timeLeft > 0) {
            timer = setInterval(() => {
                setTimeLeft(prev => {
                    if (prev <= 1) {
                        loginWithRedirect();
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [open, timeLeft, loginWithRedirect]);

    const handleRenew = async () => {
        try {
            await getAccessTokenSilently({ ignoreCache: true });
            setOpen(false);
            setTimeLeft(300);
        } catch (error) {
            loginWithRedirect();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => {}}  // Prevent closing by clicking outside
            disableEscapeKeyDown  // Prevent closing with Esc key
        >
            <DialogTitle>Session Expiring Soon</DialogTitle>
            <DialogContent>
                <Typography>
                    Your session will expire in {Math.floor(timeLeft / 60)}:
                    {String(timeLeft % 60).padStart(2, '0')} minutes.
                </Typography>
                <LinearProgress
                    variant="determinate"
                    value={(timeLeft / 300) * 100}
                    sx={{ mt: 2 }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRenew} color="primary" variant="contained">
                    Renew Session
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SessionWarning;