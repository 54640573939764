import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Typography, Button, Select, MenuItem, FormControl, InputLabel, Box, CircularProgress, TextField
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { useAuth0 } from "@auth0/auth0-react";

const TradeHistory = () => {
    const [trades, setTrades] = useState([]);
    const [sortOrder, setSortOrder] = useState('desc');
    const [monthFilter, setMonthFilter] = useState('');
    const [yearFilter, setYearFilter] = useState('');
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
    const [endDate, setEndDate] = useState(dayjs());
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { getAccessTokenSilently } = useAuth0();

    const fetchTradeHistory = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const token = await getAccessTokenSilently();
            const response = await axios.get('/api/trade-history', {
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString()
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setTrades(response.data.map(trade => ({
                ...trade,
                date: dayjs(trade.time).format('YYYY-MM-DD HH:mm:ss')
            })));
        } catch (err) {
            setError('Failed to fetch trade history. Please try again later.');
            console.error('Error fetching trade history:', err);
        } finally {
            setIsLoading(false);
        }
    }, [getAccessTokenSilently, startDate, endDate]); // Added all dependencies

    useEffect(() => {
        fetchTradeHistory();
    }, [fetchTradeHistory]); // Only depend on fetchTradeHistory since it includes all necessary dependencies

    const sortedAndFilteredTrades = trades
        .filter(trade => {
            const tradeDate = dayjs(trade.date);
            return (!monthFilter || tradeDate.month() + 1 === parseInt(monthFilter)) &&
                (!yearFilter || tradeDate.year() === parseInt(yearFilter));
        })
        .sort((a, b) => {
            return sortOrder === 'desc'
                ? new Date(b.date) - new Date(a.date)
                : new Date(a.date) - new Date(b.date);
        });

    const handleSortChange = () => {
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    };

    const csvData = sortedAndFilteredTrades.map(trade => ({
        Asset: trade.asset,
        Price: trade.price,
        Quantity: trade.quantity,
        Date: trade.date
    }));

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ padding: 2 }}>
                <Typography variant="h4" gutterBottom>Trade History</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                    <Box>
                        <Button onClick={handleSortChange} variant="outlined" sx={{ marginRight: 1 }}>
                            Sort {sortOrder === 'desc' ? 'Oldest First' : 'Newest First'}
                        </Button>
                        <CSVLink data={csvData} filename={"trade_history.csv"}>
                            <Button variant="contained">Download CSV</Button>
                        </CSVLink>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel>Month</InputLabel>
                            <Select
                                value={monthFilter}
                                label="Month"
                                onChange={(e) => setMonthFilter(e.target.value)}
                            >
                                <MenuItem value="">All</MenuItem>
                                {[...Array(12)].map((_, i) => (
                                    <MenuItem key={i} value={i + 1}>{dayjs().month(i).format('MMMM')}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel>Year</InputLabel>
                            <Select
                                value={yearFilter}
                                label="Year"
                                onChange={(e) => setYearFilter(e.target.value)}
                            >
                                <MenuItem value="">All</MenuItem>
                                {[...Array(5)].map((_, i) => {
                                    const year = dayjs().year() - i;
                                    return <MenuItem key={year} value={year}>{year}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                {isLoading ? (
                    <CircularProgress />
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Asset</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedAndFilteredTrades.map((trade, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{trade.asset}</TableCell>
                                        <TableCell>${parseFloat(trade.price).toFixed(2)}</TableCell>
                                        <TableCell>{trade.quantity}</TableCell>
                                        <TableCell>{dayjs(trade.date).format('YYYY-MM-DD')}</TableCell>
                                        <TableCell>{dayjs(trade.date).format('HH:mm:ss')}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
        </LocalizationProvider>
    );
};

export default TradeHistory;