export const ROLES = {
    ADMIN: 'admin',
    TRADER: 'trader',
    VIEWER: 'viewer'
};

export const PERMISSIONS = {
    CREATE_TRADE: 'create:trade',
    READ_TRADE: 'read:trade',
    MANAGE_USERS: 'manage:users',
    VIEW_ANALYTICS: 'view:analytics'
};